import React, { ReactElement, useEffect, useState } from "react";
import heart1 from "./assets/heart.gif";
import heart2 from "./assets/heart2.gif";
import sunflower from "./assets/sunflower.gif";
import anniversary from "./assets/anniversary.gif";
import "./App.css";
import moment from "moment";
import { SweetMessage } from "./components/SweetMessage";
import Countdown from "./components/Countdown";

function App() {
  const gifOptions = [heart1, heart2, sunflower];

  const [gif, setGif] = useState(
    gifOptions[Math.floor((Math.random() * 100) % gifOptions.length)]
  );

  const sweetMessages = [
    "Falling asleep and waking up next to you is one of my favorite things in this world",
    "I can't wait to give you another hug",
    "*Send you 8 billion back rubs*",
    "You fill my life with sunshine and joy that only you can bring me",
    "Every time I see you my day gets brighter",
    "I love you",
    "You are beautiful",
    "FAT HAMMY",
    "You are wonderful",
    "You are a perfect 10",
    "My honey bearrrrrrrrr, come get this honey",
    "Every day with you is a dream",
    "I can't wait to see you again",
    "Im very happy to be in this life with you",
  ];
  const today = moment().format("dddd");
  const date = moment();
  const targetDate = date.isBefore(`${date.year()}-10-2`, "day")
    ? new Date(date.year(), 9, 2)
    : new Date(date.year() + 1, 9, 2);
  const [currComp, setCurrComp] = useState<ReactElement[]>([]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setCurrComp((arr) =>
        arr.filter((_, index) => index !== currComp.length - 1)
      ); // *
    }, 5000);

    return () => clearTimeout(timer);
  }, [currComp]);

  return (
    <div className="App">
      {date.isSame(`${date.year()}-10-2`, "day") ? (
        <div className="aniv">{"Happy Anniversary honey bear! <3 <3"}</div>
      ) : (
        <div className="timer">
          <Countdown targetDate={targetDate}></Countdown>
        </div>
      )}
      <img
        src={date.isSame(`${date.year()}-10-2`, "day") ? anniversary : gif}
        alt="logo"
      />
      <p>{`Hi Linh! I love you, I hope you are having a good ${today}`}</p>
      <button
        className="nice-things-button"
        onClick={(e) => {
          setCurrComp([
            ...currComp,
            <SweetMessage
              text={
                sweetMessages[Math.floor(Math.random() * sweetMessages.length)]
              }
            ></SweetMessage>,
          ]);
        }}
      >
        Click me for something sweet!
      </button>
      {currComp.map((item) => {
        return item;
      })}
    </div>
  );
}

export default App;
