export function SweetMessage(props: { text: string }) {
  let top = Math.floor(Math.random() * 100);
  let left = Math.floor(Math.random() * 100);
  return (
    <div
      className="something-sweet"
      style={{
        top: top > 700 ? `${top - 700}%` : `${top}%`,
        left: left > 700 ? `${left - 700}%` : `${left}%`,
      }}
    >
      {props.text}
    </div>
  );
}
